$borderWidth: 4px;
$animationTime: 1.5s;
$border-color-default: #eee;
$border-color-fill: #00e369;
$size: 40px;

//Create how many steps
$howManySteps: 10; //this needs to be even.
//for fun try using 20 and changine in the HTML the data-percentage to 15 or 85

.progress-c {
    width: $size;
    height: $size;
    line-height: $size;
    background: none;
    margin: 0 0;
    box-shadow: none;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $borderWidth solid $border-color-default;
        position: absolute;
        top: 0;
        left: 0;
    }

    > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress-c-left {
        left: 0;
    }

    .progress-c-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: $borderWidth;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $border-color-fill;
    }

    .progress-c-left .progress-c-bar {
        left: 100%;
        border-top-right-radius: ($size/2);
        border-bottom-right-radius: ($size/2);
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
        //animation: loading-2 1.5s linear forwards 1.8s;
    }

    .progress-c-right {
        right: 0;

        .progress-c-bar {
            left: -100%;
            border-top-left-radius: ($size/2);
            border-bottom-left-radius: ($size/2);
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            //animation: loading-1 1.8s linear forwards;
        }
    }

    .progress-c-value {
        display: flex;
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        height: 100%;
        //font-family: $work-sans;
        font-weight: 300;

        div {
            margin-top: 2px;
        }

        span {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}

/* This for loop creates the 	necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
    $stepName: ($i * (100 / $howManySteps));

    //animation only the left side if below 50%
    @if $i <=($howManySteps/2) {
        .progress-c[data-percentage="#{$stepName}"] {
            .progress-c-right .progress-c-bar {
                animation: loading-#{$i} $animationTime linear forwards;
            }

            .progress-c-left .progress-c-bar {
                animation: 0;
            }
        }
    }

    //animation only the right side if above 50%
    @if $i >($howManySteps/2) {
        .progress-c[data-percentage="#{$stepName}"] {
            .progress-c-right .progress-c-bar {
                animation: loading-#{($howManySteps/2)}
                    $animationTime
                    linear
                    forwards; //set the animation to longest animation
            }

            .progress-c-left .progress-c-bar {
                animation: loading-#{$i -
                    ($howManySteps/2)}
                    $animationTime
                    linear
                    forwards
                    $animationTime;
            }
        }
    }
}

//animation
@for $i from 1 through ($howManySteps/2) {
    $degrees: (180/ ($howManySteps/2));
    $degrees: ($degrees * $i);

    @keyframes loading-#{$i} {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate($degrees);
            transform: rotate(#{$degrees}deg);
        }
    }
}

.progress {
    &.h-24 {
        height: 18px !important;
        border-radius: 16px !important;
    }
}

.bg-progress-1 {
    background-color: #9747ff !important;
    border-radius: 16px !important;
}

.bg-progress-2 {
    background-color: #079561 !important;
    border-radius: 16px !important;
}
