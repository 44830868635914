.dashboard-header {
    border-bottom: 1px solid $drop-border-color;
    padding-block: 8px;
    background-color: white;
}

.nav.nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    a.nav-link {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid $drop-border-color;
        @extend .text-gray-300;

        &:active,
        &:hover,
        &:focus,
        &:focus-within {
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
        }

        &.active {
            color: #323232;
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            border-bottom: 2px solid #323232 !important;
        }
    }

    &.bg-white {
        a.nav-link {
            background-color: white !important;
        }
    }
}

.nm-list {
    background-color: white;
    border-radius: 24px;
    border: 1px solid $drop-border-color;

    li {
        padding: 20px 20px;
        &:not(:last-child) {
            border-bottom: 1px solid $drop-border-color;
        }

        &:first-child {
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }

        &:last-child {
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
        }
    }

    &.separator {
        background-color: transparent;
        border: unset !important;

        li {
            margin-bottom: 24px;
            background-color: white;
            border-radius: 24px;
            border-bottom: 0px !important;
        }
    }

    &[data-error="true"] {
        border: 1px solid red;
    }
}

.content-card {
    border-radius: 16px;
    border: 1px solid $drop-border-color;

    .card-heading {
        border-bottom: 1px solid $drop-border-color;
        padding: 18px 24px;
    }

    &--list {
        li {
            padding: 24px;

            &:not(:last-child) {
                border-bottom: 1px solid $drop-border-color;
            }
        }
    }
}

.form-row {
    padding: 20px 16px !important;

    &.split {
        padding: 0 !important;

        & > div {
            padding: 20px 16px 20px 16px !important;
        }
    }
}

.no-hover {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        color: inherit !important;
    }
}

.avatar {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    background-color: orange;
    color: white;

    &[data-color="primary"] {
        background-color: $primary-color;
        color: white;
    }

    &[data-color="gold"] {
        background-color: gold;
        color: white;
    }

    &[data-color="gray"] {
        background-color: $drop-border-color;
        color: black;
    }

    &[data-color="brown"] {
        background-color: brown;
        color: white;
    }
    &[data-color="purple"] {
        background-color: purple;
        color: white;
    }

    &.sm {
        width: 24px;
        height: 24px;
        font-size: 14px;
    }

    &.md {
        width: 32px;
        height: 32px;
        font-size: 16px;
    }

    &.lg {
        width: 48px;
        height: 48px;
        font-size: 20px;
    }

    &.xl {
        width: 64px;
        height: 64px;
        font-size: 24px;
    }
}

.forum-top-bar {
    background-color: white;
    border-radius: 24px;
    // border-radius: 60px;

    padding: 16px;
    margin-bottom: 16px;

    form {
        input {
            border-radius: 60px;
        }

        .titlebar {
            position: relative;
            button {
                position: absolute;
                top: -1px;
                right: -8px;
            }
        }
    }
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 8px;

    & + input {
        padding-left: 40px !important;
    }
}

.crop-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10000 !important;

    .crop-section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 60vh;

        &--layout {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: inherit;
        }
    }
}

.changePass-desc {
    padding-inline: 16px;
    margin-block: 32px;
}
