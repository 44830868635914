.course-card {
    border-radius: 20px;
    border: 1px solid #efefef;
    background-color: white;

    img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom: 0;
        max-height: 200px;
        object-fit: cover;
    }
}

.userAvatar {
    button {
        display: none;
        width: 24px;
        height: 24px;
        align-items: center;
        line-height: 1;
        border-radius: 50%;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        button {
            display: flex;
        }
    }
}

li[data-read="false"] {
    position: relative;

    &::before {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "•";
        color: red;
    }
}

button.noti {
    position: relative;
    &::before {
        position: absolute;
        left: 2px;
        top: 4px;
        transform: translate(-50%, -50%);
        content: "•";
        font-size: 24px;
        color: red;
    }
}

button.showFullScreen {
    position: absolute;
    // top: 16px;
    // right: 40px;
    bottom: 40px;
    right: 40px;
    top: unset;
    z-index: 1000 !important;
}

iframe[data-full="true"] {
    border-radius: 0 !important;
    border: unset !important;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    z-index: 100000 !important;

    & + button.showFullScreen {
        position: fixed;
        z-index: 100001 !important;
        bottom: 20px;
        right: 20px;
        top: unset;
    }
}

.card {
    &.card-skeleton {
        min-height: 280px;
        width: 100%;
        box-shadow: unset;
        border: unset;
        position: relative;
        overflow: hidden;
        background-color: #dddbdd;
        border-radius: 20px;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: shimmer 5s infinite;
            content: "";
        }
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
