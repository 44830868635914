@use "./config/env" as *;
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// @import "bootstrap/scss/buttons";

@import "./config/utils";

@import "bootstrap";
@import "./config/override";

$font-rubik: "Rubik", sans-serif;

body {
    font-family: "Inter", sans-serif !important;
}

@import "./modules/landing";
@import "./modules/auth";
@import "./modules/common";
@import "./modules/quiz";
@import "./modules/user";

.forumCardShadow {
    box-shadow: 0px 0px 20px 8px #00000014;
}

.btn-nobg {
    background-color: unset;
    border: unset;
}

@media screen and (min-width: 1300px) {
    .box-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
    }
}

tr[data-disabled=true] {
    td, th {
        background-color: #f6f6f6;
        &:hover {
            background-color: #f6f6f6;
        }
    }

    &:hover {
        background-color: #f6f6f6 !important;
        th, td {
            background-color: #f6f6f6 !important;
        }
    }
}