.calendar-root {
    --grid-gap: 1px;
    --grid-border: 1px;
    --grid-height: auto;
    --text-color-light: rgba(0, 0, 0, 0.4);
    --text-color-primary: rgba(0, 0, 0, 0.7);
    // --grid-background-color: rgb(238, 238, 238);
    --grid-background-color: #efefef;
    --grid-foreground-color: white;
    --space-sm: 4px;
    --space-md: 8px;
    --space-lg: 16px;
    width: 100%;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.calendar-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 0px 0px 0;
    border: 1px solid #efefef;
    border-bottom: 0;
    // background-color: #f5f5f5;
    // border-bottom: 1px solid #ececec;
}

.day-of-week-header-cell {
    color: var(--text-color-primary);
    background-color: var(--grid-foreground-color);
    padding: var(--space-md) 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.days-of-week,
.days-grid {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: var(--grid-gap);
}

.days-of-week {
    border: var(--grid-border) solid var(--grid-background-color);
    border-bottom: 0;
    background-color: var(--grid-background-color);

    .day-of-week-header-cell {
        padding: 12px 12px 12px 12px;
        box-sizing: border-box;
        color: #8b8b8b;
        font-weight: bold;
        font-size: 16px;
    }
}

.days-grid {
    height: var(--grid-height);
    position: relative;
    border: var(--grid-border) solid var(--grid-background-color);
    background-color: var(--grid-background-color);
}

.day-grid-item-container {
    position: relative;
    background-color: var(--grid-foreground-color);
    display: flex;
    flex-direction: column;

    &.current-date {
        .day-grid-item-header span {
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: #115768;
            border-radius: 50%;
        }
    }

    &:not(.current-month) {
        .day-grid-item-header {
            color: var(--text-color-light);
        }
    }

    .day-grid-item-header {
        color: var(--text-color-primary);
        padding: var(--space-md);
        padding-bottom: 0;
        flex-shrink: 0;
        font-weight: bold;
        font-size: 16px;
    }

    & > .day-content-wrapper {
        position: relative;
        height: 130px !important;
        overflow-y: auto;

        ul.action-pills {
            list-style: none;
            padding-inline: 12px;

            .month-item {
                cursor: pointer;
                text-align: center;
                margin-bottom: 2px;
                width: 100%;
                padding: 4px 0 2px;

                .bs-1 {
                    background-color: #079561;
                    color: white;
                }

                .bs-2 {
                    background-color: #e46322;
                    color: white;
                }
            }
        }
    }
}

.navigation-header {
    display: flex;
    padding-bottom: 16px;

    & > * {
        margin-right: var(--space-md);
    }

    .top {
        border-bottom: 1px solid #efefef;
    }
}

.day-renderer {
    position: sticky;
    top: 0;
    position: -webkit-sticky;
    z-index: 1;
    left: 0;
    width: 100%;
    background-color: white;
}

.calendar-card {
    border-radius: 12px;
    background-color: white;
}
