.quiz-option {
    position: relative;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 64px !important;

    .submission {
        &::after {
            cursor: pointer;
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: inline-block;
            border: 2px solid rgb(87, 87, 87);
        }

        &[data-checked="true"] {
            &::after {
                background-position: center;
                background-size: 30px;
                border: unset;
                @include bgImage("assets/icons/checked-circle.svg");
            }
        }

        &[data-incorrect="true"] {
            &::after {
                background-position: center;
                background-size: 20px;
                border: unset;
                @include bgImage("assets/icons/red-circle.svg");
            }
        }
    }

    input {
        border: unset;
        box-shadow: unset;
        outline: none;
        width: 100%;

        &:active,
        &:hover {
            border: unset;
            box-shadow: unset;
            outline: none;
        }
    }
}

.quiz-input {
    border: unset;
    box-shadow: unset;
    outline: none;
    width: 100%;

    &:active,
    &:hover {
        border: unset;
        box-shadow: unset;
        outline: none;
    }
}

.quiz-card {
    .sort-btn {
        position: absolute;
        left: -24px;
        top: 20px;
    }

    .remove-btn {
        position: absolute;
        right: -172px;
        top: 20px;
    }

    @media screen and (max-width: 992px) {
        .remove-btn {
            right: 0;
        }
    }
}

.border-ef {
    border: 1px solid #efefef;
}
