// modal
:root {
    --bs-border-color: #f5f5f5 !important;
}

.modal-content {
    background-color: white !important;
}
.modal-sm {
    --bs-modal-width: 480px;
}
@media (min-width: 992px) {
    .modal-md {
        --bs-modal-width: 680px;
    }
}

// buttons
.btn {
    @include button-variant(
        $btn-default-bg-color,
        $btn-default-border-color,
        $color: $primary-color
    );
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 24px;

    &.def-color {
        color: $drop-text-color !important;
    }

    &.btn-sm {
        padding: 10px 14px;
        line-height: 1;
    }
}

.btn-danger {
    @include button-variant(
        $btn-default-bg-color,
        $btn-default-border-color,
        $color: $btn-danger-color
    );

    &:hover {
        @include button-variant(
            $btn-default-bg-color,
            $btn-default-border-color,
            $color: $btn-danger-color,
            $hover-color: $btn-danger-color
        );
    }
}

.btn-primary {
    @include button-variant(
        $primary-color,
        $primary-color,
        $color: $btn-primary-color
    );

    &:disabled {
        color: $white !important;
    }
}

.btn-text {
    color: $primary-color;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus-within,
    &:focus,
    &:focus-visible {
        color: $primary-color !important;
        opacity: 0.8 !important;
        background-color: transparent !important;
        border-color: transparent !important;
    }
}

.btn.dropdown-toggle {
    &::after {
        content: "";
        height: 16px;
        width: 16px;
        vertical-align: middle;
        margin-left: 8px;
        background-size: 16px;
        @include bgImage("assets/icons/arrow-down.svg");
        border: unset !important;
    }

    &[data-toggle-icon="false"] {
        &::after {
            content: unset;
        }
    }

    &.btn-sm {
        &::after {
            margin-left: 4px;
        }
    }

    &.btn-primary {
        &::after {
            @include bgImage("assets/icons/arrow-down-white.svg");
        }
    }
}

.btn-upload {
    border: 1px dashed $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus-within,
    &:focus {
        border: 1px dashed $primary-color;
        color: $primary-color;
    }
}

// dropdown customisation
.dropdown {
    border-radius: 24px;

    &-menu {
        border-radius: 16px;
        background-color: white;
        border-color: $drop-border-color;
        box-shadow: 0px 4px 4px 0px #00000040;

        .customDrop-item {
            padding: 4px 12px;
            font-size: 14px;
            &:not(:last-child) {
                border-bottom: 1px solid $drop-border-color;
            }
        }
    }
}

// input
.form-floating {
    position: relative;
    label {
        padding: 1rem 1rem;
        &::after {
            background-color: $btn-default-bg-color !important;
        }
    }

    .icon {
        position: absolute;
        bottom: 15%;
        transform: translateY(0%);
        right: 24px;
    }
}

input.form-control,
select.form-select,
textarea.form-control {
    @extend .text-gray-600;
    border-radius: 12px;
    border: 1px solid $drop-border-color;
    background-color: $btn-default-bg-color;
    padding-inline: 1rem !important;
    font-weight: 600;

    &[name="search"],
    &.normal {
        font-weight: 400;
    }

    &.no-resize {
        resize: none;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
        outline: unset;
        box-shadow: unset;
        background-color: $btn-default-bg-color;
    }
}

.form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
}

.nm-multi-select {
    .nm-multi__control {
        font-size: 14px;
        border-radius: 12px;
        border: 1px solid $drop-border-color;
        background-color: $btn-default-bg-color;
        padding-inline: 0rem !important;

        .nm-multi__multi-value {
            border-radius: 20px;
            padding: 2px 8px;
            color: $primary-color;
            background-color: #1a73e81a;

            .nm-multi__multi-value__label {
                color: $primary-color;
            }

            .nm-multi__multi-value__remove {
                border: 1px solid $primary-color;
                border-radius: 50%;
                align-self: center;
                padding: 1px;
                margin-left: 6px;

                &:hover {
                    border-radius: 20px;
                    background-color: inherit !important;
                }
            }
        }

        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active {
            border: 1px solid $drop-border-color;
            outline: unset;
        }
    }

    .nm-multi__menu {
        font-size: 14px !important;
    }
}

.nm-table {
    background-color: white;
    border-radius: 16px;
    border: 1px solid $drop-border-color;
    min-height: 400px;

    table {
        td,
        th,
        tr {
            background-color: white;
        }

        th,
        td {
            border-bottom: 1px solid #efefef;
        }

        th {
            @extend .text-gray-300;
            font-size: 12px;
            font-weight: 500;
            padding-block: 10px;
        }

        td {
            vertical-align: middle;
            font-size: 14px;
            padding-block: 12px;

            span.ellipsis {
                width: inherit;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                overflow: hidden;
            }
        }

        tbody {
            tr {
                &[data-ptr="true"] {
                    cursor: pointer;
                }
                &:hover {
                    td {
                        background-color: #fafafa;
                    }
                }
            }
        }

        tr {
            th:first-child,
            td:first-child {
                padding-left: 16px;
            }
        }
    }

    &[data-empty="true"] {
        position: relative;

        &::after {
            position: absolute;
            content: "No Data";
            @extend .text-gray-300;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.mw-22 {
    min-width: 22px;
}

.phoneExtension {
    border: 1px solid $drop-border-color;
    background-color: $btn-default-bg-color;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
