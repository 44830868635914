.auth-card {
    border: 1px solid $drop-border-color;
    border-radius: 24px;
    background-color: #fff;
    width: calc(100vw - 70%);

    @media screen and (max-width: 1200px) {
        width: 50%;
    }

    @media screen and (max-width: 768px) {
        width: 75%;
    }
    @media screen and (max-width: 576px) {
        width: 90%;
    }
}
