.dark-linear-gradient {
    background: linear-gradient(180deg, #16453d 0%, #082621 100%);
}

.primary-dark-color {
    color: $primary-dark-color !important;
}

#hero {
    font-family: $font-rubik;
}

.top-menu {
    font-weight: 600;
    padding: 12px;
    background-color: white;
    border-bottom: 1px solid #efefef;
    font-family: $font-rubik;

    .info {
        gap: 60px;
    }

    @media screen and (max-width: 1200px) {
        .info {
            gap: 60px;
            overflow-x: scroll;
        }
    }
    @media screen and (max-width: 991px) {
        .info {
            margin-left: 16px;
            gap: 10px;
            overflow-x: scroll;

            .box {
                min-width: 254px;
            }
        }
    }
}

header {
    font-family: $font-rubik;
    background-color: white;
    padding-block: 16px;
    border-bottom: 1px solid #fff;
    .header-menu {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: 12px;
                }
                a {
                    font-weight: 500;
                    @extend .text-gray-400;
                    display: inline-block;
                    text-decoration: none !important;
                    padding: 12px 24px;
                    border-radius: 40px;
                    &.active {
                        background-color: #0795610d;
                        color: $primary-dark-color !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        position: relative;
        .header-menu {
            ul {
                display: none !important;
                padding-left: 16px;
                border-top: 1px solid #f5f5f5;
                border-bottom: 1px solid #f5f5f5;
                padding-block: 16px;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: white;

                &[data-expanded="true"] {
                    display: flex !important;
                }
            }
        }
    }
}

.our-roadmap,
.about-section,
.our-team,
.making-difference {
    background-color: #ffffff;
    font-family: $font-rubik;
}

.head.text-primary {
    @extend .primary-dark-color;
}

.our-roadmap {
    padding-block: 80px;

    ul.timeline {
        counter-reset: year 0;
        position: relative;
        display: flex;
        padding-left: 0;

        li {
            list-style: none;
            width: 25%;
            position: relative;
            text-align: center;

            &:before {
                counter-increment: year;
                content: counter(year);
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                background: $primary-dark-color;
                color: white;
                z-index: 2;
                transition: all ease-in-out 0.3s;
                cursor: pointer;
            }
            &:after {
                content: "";
                position: absolute;
                width: 95%;
                height: 1px;
                border-top: 2px dashed rgba($primary-dark-color, 0.3);
                top: 25px;
                left: -45%;
                z-index: 0;
                transition: all ease-in-out 0.3s;
            }
            &:first-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        padding-block: 80px 40px;
    }
}

.about-section {
    padding-block: 80px;

    p {
        line-height: 32px;
        text-align: justify;
        font-size: 20px;
    }
}

.platform-features {
    padding-block: 48px;
    font-family: $font-rubik;
    @extend .dark-linear-gradient;

    h3 {
        font-weight: bolder;
    }

    .card {
        border-radius: 24px;
        border: unset !important;
        background-color: #0000001a !important;
    }
}

.making-difference {
    padding-block: 56px;

    .diff-list {
        li {
            margin-bottom: 32px;
            line-height: 2;
            border-radius: 24px;
            padding: 24px;
            background-color: $body-bg !important;
            border: 1px solid $drop-border-color;

            .content {
                line-height: 32px;
            }
        }
    }
}

.our-inspiration {
    padding-block: 48px;
    font-family: $font-rubik;
    @extend .dark-linear-gradient;

    .insp-wrapper {
        justify-content: center;
        // margin-bottom: 120px;
        height: 100%;

        img {
            border-radius: 30px;
        }

        @media screen and (max-width: 1200px) {
            // margin-bottom: 24px;
        }
    }
    .insp-box {
        position: relative;
        height: 100%;
        top: -120px;
        border-radius: 24px;
        padding: 40px;
        width: 80%;
        left: 10%;
        margin-left: -8px;

        &.one {
            background-color: #edefff;
            // bottom: -42.5%;

            .text-primary {
                color: #0011ab !important;
            }
        }

        &.two {
            background-color: #e6fdf0;
        }

        &.three {
            background-color: #ffe9ec;

            .text-primary {
                color: #b20000 !important;
            }
        }

        &.four {
            background-color: #e6f0ff;

            .text-primary {
                color: #0066ff !important;
            }
        }

        &.five {
            background-color: #f4ebe6;

            .text-primary {
                color: #8f3502 !important;
            }
        }

        @media screen and (max-width: 1200px) {
            position: relative;
            bottom: 0;
            top: -30px !important;
            left: 0;
            width: 100%;
            margin-left: 0;
        }
    }

    .slick-slider {
        .slick-arrow {
            &.slick-prev,
            &.slick-next {
                z-index: 100;
                background-color: white !important;
                height: 42px;
                width: 42px;
                top: 46%;
                vertical-align: middle;
                border-radius: 24px;
                &::before {
                    content: "";
                    border-radius: 24px;
                    background-repeat: no-repeat;
                    background-size: 42px;
                    display: block;
                    height: 42px;
                    width: 42px;
                    font-size: 24px;
                    color: black !important;
                    background-color: white !important;
                }
            }
            &.slick-prev {
                left: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15 6l-6 6l6 6' /%3E%3C/svg%3E");
                }
            }
            &.slick-next {
                right: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 6l6 6l-6 6' /%3E%3C/svg%3E");
                }
            }

            @media screen and (max-width: 991px) {
                &.slick-prev {
                    left: -50px;
                }
                &.slick-next {
                    right: -50px;
                }
            }

            @media screen and (max-width: 768px) {
                &.slick-prev,
                &.slick-next {
                    height: 32px;
                    width: 32px;
                    &::before {
                        background-size: 32px;
                        height: 32px;
                        width: 32px;
                    }
                }

                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }
}

.gallery {
    padding-block: 64px;
    font-family: $font-rubik;
}

.contact-us {
    padding-block: 48px;
    font-family: $font-rubik;
    @extend .dark-linear-gradient;

    input,
    textarea {
        padding: 12px;
        border-radius: 12px !important;
        border: unset !important;
        background-color: #ffffff0d !important;
        color: white !important;
        resize: none;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            background-color: #ffffff0d !important;
            color: white !important;
            border: unset !important;
            outline: unset !important;
        }

        &::placeholder {
            color: white !important;
        }

        ::-ms-input-placeholder {
            color: white !important;
        }
    }

    textarea {
        height: 100%;
    }
}

footer {
    background-color: #131313;
    padding: 56px;
    font-family: $font-rubik;
}

.w-lg-100 {
    @media screen and (min-width: 992px) {
        width: 100%;
    }
}

#gallery {
    .slick-slider {
        .slick-arrow {
            &.slick-prev,
            &.slick-next {
                z-index: 100;
                background-color: white !important;
                height: 42px;
                width: 42px;
                vertical-align: middle;
                border-radius: 24px;
                &::before {
                    content: "";
                    border-radius: 24px;
                    background-repeat: no-repeat;
                    background-size: 42px;
                    display: block;
                    height: 42px;
                    width: 42px;
                    font-size: 24px;
                    color: black !important;
                    background-color: white !important;
                }
            }
            &.slick-prev {
                left: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15 6l-6 6l6 6' /%3E%3C/svg%3E");
                }
            }
            &.slick-next {
                right: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 6l6 6l-6 6' /%3E%3C/svg%3E");
                }
            }

            @media screen and (max-width: 991px) {
                &.slick-prev {
                    left: -50px;
                }
                &.slick-next {
                    right: -50px;
                }
            }

            @media screen and (max-width: 768px) {
                &.slick-prev,
                &.slick-next {
                    height: 32px;
                    width: 32px;
                    &::before {
                        background-size: 32px;
                        height: 32px;
                        width: 32px;
                    }
                }

                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }
    img.img-fluid {
        border-radius: 24px;
    }
}

#hero {
    .slick-slider {
        .slick-arrow {
            &.slick-prev,
            &.slick-next {
                z-index: 100;
                background-color: white !important;
                height: 42px;
                width: 42px;
                vertical-align: middle;
                border-radius: 24px;
                &::before {
                    content: "";
                    border-radius: 24px;
                    background-repeat: no-repeat;
                    background-size: 42px;
                    display: block;
                    height: 42px;
                    width: 42px;
                    font-size: 24px;
                    color: black !important;
                    background-color: white !important;
                }
            }
            &.slick-prev {
                left: 20px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15 6l-6 6l6 6' /%3E%3C/svg%3E");
                }
            }
            &.slick-next {
                right: 20px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 6l6 6l-6 6' /%3E%3C/svg%3E");
                }
            }

            // @media screen and (max-width: 991px) {
            //     &.slick-prev {
            //         left: -50px;
            //     }
            //     &.slick-next {
            //         right: -50px;
            //     }
            // }

            // @media screen and (max-width: 768px) {
            //     &.slick-prev,
            //     &.slick-next {
            //         height: 32px;
            //         width: 32px;
            //         &::before {
            //             background-size: 32px;
            //             height: 32px;
            //             width: 32px;
            //         }
            //     }

            //     &.slick-prev {
            //         left: 0;
            //     }
            //     &.slick-next {
            //         right: 0;
            //     }
            // }
        }
    }

    .hero-list {
        margin-top: 32px;
        .item {
            font-weight: 600;
            display: flex;
            align-items: center;
            background-color: #ffffffd9;
            padding: 12px;
            font-size: 16px;
            border-radius: 64px;
            margin-bottom: 12px;
        }
    }

    .bg-1 {
        background-color: #0f76501a;
    }

    .bg-2 {
        background-color: #f24e1e1a;
    }
}

.contactForm {
    input,
    textarea {
        padding: 20px !important;
    }
}

.our-team {
    padding-block: 48px;
    font-family: $font-rubik;

    .insp-wrapper {
        justify-content: center;
        height: 100%;
    }

    .slick-slider {
        .slick-arrow {
            &.slick-prev,
            &.slick-next {
                z-index: 100;
                background-color: white !important;
                height: 42px;
                width: 42px;
                top: 46%;
                vertical-align: middle;
                border-radius: 24px;
                &::before {
                    content: "";
                    border-radius: 24px;
                    background-repeat: no-repeat;
                    background-size: 42px;
                    display: block;
                    height: 42px;
                    width: 42px;
                    font-size: 24px;
                    color: black !important;
                    background-color: white !important;
                }
            }
            &.slick-prev {
                left: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15 6l-6 6l6 6' /%3E%3C/svg%3E");
                }
            }
            &.slick-next {
                right: -102px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 6l6 6l-6 6' /%3E%3C/svg%3E");
                }
            }

            @media screen and (max-width: 991px) {
                &.slick-prev {
                    left: -50px;
                }
                &.slick-next {
                    right: -50px;
                }
            }

            @media screen and (max-width: 768px) {
                &.slick-prev,
                &.slick-next {
                    height: 32px;
                    width: 32px;
                    &::before {
                        background-size: 32px;
                        height: 32px;
                        width: 32px;
                    }
                }

                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }

    .slick-list {
        margin: 0 -7px;
        & .slick-slide > div {
            padding: 0 10px;
        }
    }
}
