$primary-color: #079561;
$primary-dark-color: #0f7650;
$body-bg: #f8f9fd;
$primary-head-dark: #0f7650;

// button config
$btn-default-color: #fff;
$btn-default-bg-color: #f5f5f5;
$btn-default-border-color: #efefef;
$btn-primary-color: #fff;
$btn-danger-color: #f21e1e;

$drop-text-color: #323232;
$drop-border-color: #efefef;

$custom-colors: (
    primary: $primary-color
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@mixin bgImage($name) {
    background-image: url("#{$media_url}#{$name}");
}

.fs {
    &-6 {
        font-size: 6px !important;
    }
    &-8 {
        font-size: 8px !important;
    }
    &-10 {
        font-size: 10px !important;
    }
    &-12 {
        font-size: 12px !important;
    }
    &-14 {
        font-size: 14px !important;
    }
    &-20 {
        font-size: 1.25rem !important;
    }
    &-24 {
        font-size: 1.5rem !important;
    }
    &-28 {
        font-size: 1.75rem !important;
    }
    &-32 {
        font-size: 2rem !important;
    }
    &-36 {
        font-size: 2.25rem !important;
    }
    &-48 {
        font-size: 3rem !important;
    }
    &-54 {
        font-size: 3.375rem !important;
    }

    @media screen and (max-width: 991px) {
        &-36 {
            font-size: 2rem !important;
        }
        &-48 {
            font-size: 2.75rem !important;
        }
        &-54 {
            font-size: 3rem !important;
        }
    }

    @media screen and (max-width: 768px) {
        &-36 {
            font-size: 1.875rem !important;
        }
        &-48 {
            font-size: 2.25rem !important;
        }
        &-54 {
            font-size: 2.75rem !important;
        }
    }
}

.text {
    &-gray {
        &-100 {
            color: #f5f5f5;
        }
        &-200 {
            color: #d1d1d1;
        }
        &-300 {
            color: #8b8b8b;
        }
        &-400 {
            color: #585858;
        }
        &-600 {
            color: #323232;
        }
    }

    &-dark-primary {
        color: #0f7650 !important;
    }

    &-dark-head {
        color: $primary-head-dark;
    }

    &-red-p {
        color: #f24e1e !important;
    }

    &-blue {
        color: #0000ee !important;
    }
}

.fw {
    &-600 {
        font-weight: 600;
    }
}

.bg {
    &-gray-100 {
        background-color: #f5f5f5 !important;
    }
    &-light-danger {
        background-color: #f24e1e0d !important;
        color: #f24e1e !important;
    }
    &-light-primary {
        background-color: rgba(15, 169, 88, 0.1) !important;
    }
}

.h-inherit {
    height: inherit;
}

.default-loader {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
    background-color: #ffffff99;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.fixed {
        position: fixed;
    }
}

.notification-drop {
    min-height: 200px;
    max-height: 400px;
    border-radius: 8px !important;
    min-width: 380px !important;

    .drop-list {
        overflow-y: auto;
        max-height: 380px;
        li {
            padding: 12px 20px;
            &:not(:last-child) {
                border-bottom: 1px solid $btn-default-border-color;
            }
        }
    }
}

.recent-list {
    li {
        padding: 12px 20px;
        &:not(:last-child) {
            border-bottom: 1px solid $btn-default-border-color;
        }
    }
}

.cp,
.cp > * {
    cursor: pointer;
}

.br-60 {
    border-radius: 60px !important;
}
